import { Component } from "react";
import TokenContext from "../Contexts/Token";
import { Navigate } from "react-router-dom";
import TransferMoney from "../Services/TransferMoney";
import Transactions from "../Services/Transactions";
import Loans from "../Services/Loans";
import Disputes from "../Services/Disputes";
import AccountDetails from "../Components/AccountDetails";
class Home extends Component{
    static contextType = TokenContext;
    state={
        error:'',
        data:{},
        bool: false,
        isLoaded: false,
        tx:[]
    }
    componentDidMount(){
        this.getData();
    }
    async getData(){
        let res = await fetch(`http://client.api.safeportal.win/api/account/accountdetails?username=${this.context[0].username}`,{
            method: 'GET',
            headers:{
                'Authorization': 'Bearer '+this.context[0].token
            }
        });
        let body = await res.json();
        //console.log(body);
        if(body.status===401){
            return this.setState({error:"UNAUTHORIZED"});
        }else{
            this.setState({...this.state,data:body, isLoaded:true}) //
        }
    }
    doRefresh(){
        this.setState({...this.state, isLoaded:false})
        this.getData();
       // console.log(this.state);
    }
    render(){
        let err = this.state.error;
        const d = this.state.data;
        return(
            <div className="container m-2">{err? <Navigate to="/error" replace={true}/>:
            <div><div className="card m-3">
            <div className="col text-end"><button type="button" className="btn btn-warning" onClick={()=>{window.location.href='/'}}>Logout</button></div>
                <div className="card-body">
                    <h5 className="card-title">Welcome {this.context[0].username}!  </h5>
                    <div className="mt-3 p-3"><h4>Balance: ₹ <strong>{this.state.bool? d.balance:'******'}<img src={this.state.bool?'ceye.png':'oeye.png'} width="20px" height="20px" alt="eye" onClick={()=>this.state.bool?this.setState({...this.state,bool:false}):this.setState({...this.state,bool:true})}/></strong></h4></div>
                </div>
                <div className="container">
                    <div className="btn-toolbar mb-3">
                        <div className="btn-group">
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#account_details" aria-expanded="false" aria-controls="account_details" >Account details</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#transactions" aria-expanded="false" aria-controls="transactions">Transactions</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#send" aria-expanded="false" aria-controls="send">Send/Transfer</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#loans" aria-expanded="false" aria-controls="loans">Loans</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#disputes" aria-expanded="false" aria-controls="disputes">Disputes</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="data-parent">
            <div className="collapse indent card m-3 p-3" id="account_details" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Account Details</strong></div>
                <div className="card-body font-weight-bold">
                {this.state.isLoaded? <AccountDetails ac={this.state.data} session={this.context[0]}/>:"Failed to load!"}
                </div>
            </div>
            <div className="collapse indent card m-3 p-3" id="transactions" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Transactions</strong></div>
                <div className="card-body font-weight-bold">
                {this.state.isLoaded?<Transactions data={this.state.data} tx={(props)=>this.setState({...this.state, tx:props})}/>:""}
                </div>
            </div>
            <div className="collapse indent card m-3 p-3" id="send" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Send/Transfer Money</strong></div>
                <div className="card-body font-weight-bold">
                    {this.state.isLoaded?<TransferMoney session={this.context[0]} data={d} fn={()=>this.doRefresh()}/>:""}
                </div>
            </div>
            <div className="collapse indent card m-3 p-3" id="loans" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Loans</strong></div>
                <div className="card-body font-weight-bold">
                {this.state.isLoaded?<Loans session={this.context[0]} data={d} fn={()=>this.doRefresh()}/>:""}
                </div>
            </div>
            <div className="collapse indent card m-3 p-3" id="disputes" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Disputes</strong></div>
                <div className="card-body font-weight-bold">
                {this.state.isLoaded?<Disputes session={this.context[0]} data={d} fn={()=>this.doRefresh()} tx={this.state.tx.data}/>:""}
                </div>
            </div>
            </div>
            </div>
            }</div>
        )
    }
}
export default Home;