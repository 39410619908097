function DisputeDesk(props){
    const openDisputes = props.disputes.filter(i =>{
        return i.dispute_status==="Investigating";    
    });
    const closedDisputes = props.disputes.filter(i => {
        return i.dispute_status !== 'Investigating';
    });
    async function resolveDispute(i){
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/disputes/updatestatus?d=${i.dispute_id}&status=Resolved`,{
            method:'PUT',
            headers: {
                'Authorization':'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            }
        });
        let body = await res.text();
        console.log(body)
        if(body === 'Dispute status updates successfully!'){
            props.fn();
        }
    }
    async function rejectDispute(i){
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/disputes/updatestatus?d=${i.dispute_id}&status=Rejected`,{
            method:'PUT',
            headers: {
                'Authorization':'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            }
        });
        let body = await res.text();
        console.log(body)
        if(body === 'Dispute status updates successfully!'){
            props.fn();
        }
    }
    return(
        <div className="row text-center m-3">
            <div className="col">
            <h4>OPEN DISPUTES</h4>
                <table className="table">
                    <thead>
                        <th>Dispute No.</th>
                        <th>Transaction</th>
                        <th>Amount</th>
                        <th>Reason</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {openDisputes.map(i =>{
                            return(
                                <tr>
                                <td>{i.dispute_id}</td>
                                <td>{i.transactions.transaction_id}</td>
                                <td>₹{i.transactions.transaction_amount}</td>
                                <td>{i.dispute_reason}</td>
                                <td><span className="badge rounded-pill bg-success" onClick={()=>{resolveDispute(i)}}>Resolve</span> / <span className="badge rounded-pill bg-danger" onClick={()=>{rejectDispute(i)}}>Reject</span></td>
                            </tr>
                            );
                        })}
                    </tbody>   
                </table>
            </div>
            <div className="col">
            <h4>CLOSED DISPUTES</h4>
                <table className="table">
                    <thead>
                        <th>Dispute No.</th>
                        <th>Transaction</th>
                        <th>Amount</th>
                        <th>Reason</th>
                        <th>Status</th>
                    </thead>
                    <tbody>
                        {closedDisputes.map(i =>{
                            return(
                                <tr>
                                <td>{i.dispute_id}</td>
                                <td>{i.transactions.transaction_id}</td>
                                <td>₹{i.transactions.transaction_amount}</td>
                                <td>{i.dispute_reason}</td>
                                <td><p className={i.dispute_status==='Resolved'?"badge rounded-pill bg-success":"badge rounded-pill bg-danger"}>{i.dispute_status}</p></td>
                            </tr>
                            );
                        })}
                    </tbody>   
                </table>
            </div>
        </div>
    );
}
export default DisputeDesk;