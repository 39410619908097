import React from 'react'
import {Link} from 'react-router-dom'
import logo from './logo-sm.png'
function NavBar(){
    return (
        <div className="container-fluid bg-danger p-2">
        <img src={logo} alt="logo" height="50px"/>
            <Link to="/" className="Link">Home</Link>
            <Link to="/login" className="Link">Login</Link>
            <Link to="/register" className="Link">Register</Link>
            <Link to="/investors" className="Link">Investors Page</Link>
            <Link to="/staff/login" className='Link'>Staff</Link>
        </div>
    );
}
export default NavBar;