import { useState } from "react";
import LoginA from "./LoginA";
import Registration from "./Registration";

function LnR(){
    const [Login, setLogin] = useState(true);
    const [Register, setRegister] = useState(false);
    function toggleLogin(){
        if(Login){
            setRegister(true);
            setLogin(false);
        }else{
            setLogin(true);
            setRegister(false);
        }
    }
    return(
        <div className="text-center border p-2">
        <div className="display-4 text-danger mb-2">Sib's Bank</div>
        <div className="btn-toolbar col-auto text-center" role="toolbar">
            <div className="container-fluid btn-group"><button type="button" onClick={toggleLogin} id="login" className={Login?"btn btn-danger end":"btn btn-outline-danger"}>Login</button>
            <button type="button" onClick={toggleLogin} id="register" className={Register?"btn btn-danger":"btn btn-outline-danger end"}>Register</button></div>
        </div>
        {Login? <LoginA/>:<Registration/>}
        </div>
    );
}
export default LnR;