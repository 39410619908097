import { useEffect, useRef, useState } from "react";

function Disputes(props){
    const tx=props.tx;
    const transactionRef = useRef();
    const reasonRef = useRef();
    const [feedback, setFeedback] = useState();
    const [disputes, setDisputes] = useState();
    async function disputeService(){
        setFeedback('');
        const transaction = transactionRef.current.value;
        const reason = reasonRef.current.value;
        if(!reason){
            return setFeedback('Reason cannot be empty!');
        }
        const dispute = {
            "dispute_reason":reason
        }
        let res = await fetch(`http://client.api.safeportal.win/api/disputes/new?t=${transaction}`,{
            method:'POST',
            headers:{
                'Authorization': 'Bearer '+props.session.token,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(dispute)
        });
        let body = await res.text();
        setFeedback(body);
    }
    async function getDisputes(){
        let res = await fetch(`http://client.api.safeportal.win/api/disputes/fetch?c=${props.data.cust_no}`,{
            method:'GET',
            headers:{
                'Authorization': 'Bearer '+props.session.token,
                'Content-Type':'application/json'
            }
        });
        let body = await res.json();
        setDisputes(body);
        //console.log(disputes);
        if(body === 'Disputed successfully'){
            props.fn();
        }
    }
    useEffect(()=>{
        getDisputes();
    }, [feedback])
    return(
<div className="row">
                <div className="col card m-3 p-3" id="reqloan">
                <h4 className="card-title">Dispute a transaction</h4>
                <div className="card-body">
                <form>
                    <div class="input-group mb-3">
                        <select name="dtransaction" id="dtransaction" className="form-control" ref={transactionRef}>
                        {tx?tx.map(i =>{
                            return(
                                <option value={i.transaction_id}>ID:{i.transaction_id} AMOUNT:{i.transaction_amount} BENEFICIARY:{i.beneficiary}</option>
                            )
                        }):""}
                        </select>
                    </div>
                    <div><input type="text" className="form-control mb-2" placeholder="reason" ref={reasonRef}/></div>
                    <div className="text-center"><button type="button" className="btn btn-warning mb-2" onClick={disputeService}>Dispute</button></div>
                </form>
                <p className={feedback==='Disputed successfully'?"text-center text-success fw-bold":"text-center text-danger fw-bold"}>{feedback}</p>
                </div>
                </div>
                <div className="col card m-3 p-3 text-center" id="loanstatus">
                    <h4 className="card-title border-bottom">Dispute status</h4>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Dispute no</th>
                                <th>Transaction no</th>
                                <th>Reason</th>
                                <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {disputes?disputes.map(i=>{
                                return(
                                    <tr>
                                        <td>{i.dispute_id}</td>
                                        <td>{i.transactions.transaction_id}</td>
                                        <td>{i.dispute_reason}</td>
                                        <td><span className={i.dispute_status==='Investigating'?"badge rounded-pill bg-warning":i.dispute_status==='Resolved'?"badge rounded-pill bg-success":"badge rounded-pill bg-danger"}>{i.dispute_status}</span></td>
                                    </tr>
                                )
                            }):""}
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>
    )
}
export default Disputes;