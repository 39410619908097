import { useRef, useState } from "react";
import Verification from "./Verification";
function LoginA(){
    const userRef = useRef();
    const passRef = useRef();
    const [feedback, setFeedback] = useState(" ");
    const [session, setSession] = useState({token:null, username:null});
    async function Login(){
        const username = userRef.current.value.toLowerCase();
        const password = passRef.current.value;
        const data = {
            'username':username,
            'password':password
        };
        let response = await fetch(`http://client.api.safeportal.win/authenticate`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await response.json();
        //console.log(body);
        setSession({'token':body.token, 'username':username});
        if(!session.token){
            return setFeedback("INVALID USERNAME OR PASSWORD!");
        }
    }
    return(
        <div>
        <form>
            <div className="form-floating col-auto mt-3 mb-3 text-center">
                <input type="text" id="username" className="form-control col-2" placeholder="username" ref={userRef} required/>
                <label htmlFor="username">Enter username:</label>
            </div>
            <div className="form-floating">
            <input type="password" id="password" className="form-control col-2" placeholder="password" ref={passRef} required/>
                <label htmlFor="password">Enter password:</label>
            </div>
            <div className="mt-3 mb-0 pb-0">
                <button type="button" onClick={Login} className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#verification">Login</button>
            </div><br/>
            <p className="text-danger mb-0 pb-0">{session.token?<Verification session={session}/>:<strong>{feedback}</strong>}</p>
        </form>

        </div>
    );
}
export default LoginA;
