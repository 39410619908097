import { Component } from "react";
import TokenContext from '../../Contexts/Token';
import {Navigate} from 'react-router-dom';
import CreateAccount from "./CreateAccount";
import Deposit from "./Deposit";
import LoanCenter from "./LoanCenter";
import DisputeDesk from "./DisputeDesk";
class Staff extends Component{
    static contextType = TokenContext;
    state={
        error:'',
        loans:[],
        tx:[],
        disputes: [],
        isLoansLoaded: false,
        isDisputesLoaded: false,
        isTxLoaded: false
    }
    async componentDidMount(){
        await this.getLoans();
        await this.getDisputes();
        await this.getTx();
    }
    async getLoans(){
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/loans/all`,{
            method: 'GET',
            headers:{
                'Authorization': 'Bearer '+this.context[0].token
            }
        });
        let body = await res.json();
       // console.log(body);
        if(body.status===401){
            return this.setState({error:"UNAUTHORIZED"});
        }else{
            this.setState({...this.state,loans:body.reverse(), isLoansLoaded:true}) //
        }
    }
    async getTx(){
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/tx/gross`,{
            method: 'GET',
            headers:{
                'Authorization': 'Bearer '+this.context[0].token
            }
        });
        let body = await res.json();
       // console.log(body);
        if(body.status===401){
            return this.setState({error:"UNAUTHORIZED"});
        }else{
            this.setState({...this.state,tx:body.reverse(), isTxLoaded:true}) //
        }
    }
    async getDisputes(){
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/disputes/all`,{
            method: 'GET',
            headers:{
                'Authorization': 'Bearer '+this.context[0].token
            }
        });
        let body = await res.json();
       // console.log(body);
        if(body.status===401){
            return this.setState({error:"UNAUTHORIZED"});
        }else{
            this.setState({...this.state,disputes:body.reverse(), isDisputesLoaded:true}) //
        }
    }
    reloadLoan(){
        this.setState({...this.state, isLoansLoaded:false});
        this.getLoans();
    }
    reloadDisputes(){
        this.setState({...this.state, isDisputesLoaded:false});
        this.getDisputes();
    }
    render(){
        let err = this.state.error;
        let tx = this.state.tx;
        let total= 0;
        tx.forEach(i =>{
                total+=i.transaction_amount
        });
        return(
            <div className="container m-2">{err? <Navigate to="/error" replace={true}/>:
            <div><div className="card m-3">
            <div className="col text-end"><button type="button" className="btn btn-warning" onClick={()=>{window.location.href='/'}}>Logout</button></div>
                <div className="card-body">
                    <h5 className="card-title">Welcome {this.context[0].username}!  </h5>
                </div>
                <div className="container">
                    <div className="btn-toolbar mb-3">
                        <div className="btn-group">
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#create" aria-expanded="false" aria-controls="create" >Create an account</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#deposit" aria-expanded="false" aria-controls="deposit">Deposit money</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#grosstx" aria-expanded="false" aria-controls="send">View gross Transactions</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#loans" aria-expanded="false" aria-controls="loans">Loan center</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#disputes" aria-expanded="false" aria-controls="disputes">Dispute desk</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="data-parent">
            <div className="collapse indent card m-3 p-3" id="create" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Create an account</strong></div>
                <CreateAccount session={this.context[0]} /> 
            </div>
            <div className="collapse indent card m-3 p-3" id="deposit" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><h1>Deposit Money</h1></div>
                <Deposit session={this.context[0]}/>
            </div>
            <div className="collapse indent card m-3 p-3" id="grosstx" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Gross Transactions</strong></div>
            <div className="m-2 p-2">
                <h5>Total Money Transacted: {total}</h5>
                <h5>Total Transactions: {tx.length}</h5>
            </div>
                <table className="table">
                    <thead className="text-center">
                        <tr>
                            <th>Transaction ID</th>
                            <th>Sender</th>
                            <th>Amount</th>
                            <th>Receiver</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody className="text-uppercase text-center">
                        {this.state.isTxLoaded? tx.map(i=>{
                            return(<tr>
                                <td>{i.transaction_id}</td>
                                <td className="text-danger">{i.accountDetails.user.username}</td>
                                <td>{i.transaction_amount}</td>
                                <td className="text-success">{i.beneficiary}</td>
                                <td>{i.date}</td>
                            </tr>
                            )
                        }):""}
                    </tbody>
                </table>
            </div>
            <div className="collapse indent card m-3 p-3" id="loans" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Loan center</strong></div>
                {this.state.isLoansLoaded?<LoanCenter session={this.context[0]} loans={this.state.loans} fn={()=>this.reloadLoan()}/>:'Failed to load!'} 
            </div>
            <div className="collapse indent card m-3 p-3" id="disputes" data-bs-parent="#data-parent">
            <div className="card-header text-center text-black"><strong>Dispute desk</strong></div>
                {this.state.isDisputesLoaded?<DisputeDesk session={this.context[0]} disputes={this.state.disputes} fn={()=>{this.reloadDisputes()}}/>:'Failed to load!'}
            </div>
            </div>
            </div>
            }</div>
        );
    }
}
export default Staff;