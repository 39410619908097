import { useEffect, useRef, useState } from "react";

function Loans(props){
    const amountRef = useRef();
    const tenureRef = useRef();
    const [data, setData] = useState([]);
    const [feedback, setFeedback] = useState();
    async function loanService(){
        const loan_amount = parseInt(amountRef.current.value);
        const loan_tenure = parseInt(tenureRef.current.value);
        const interest = 17.00;
        const loan ={
            "loan_amount":loan_amount,
            "loan_tenure":loan_tenure,
            "interest":interest
        };
        //console.log(loan);
        if(loan_amount<=0){
            return setFeedback("AMOUNT CANNOT BE 0 OR NEGATIVE");
        }
        let res = await fetch(`http://client.api.safeportal.win/api/loans/new?c=${props.data.cust_no}`,{
            'method':'POST',
            'headers':{
                'Authorization': 'Bearer '+props.session.token,
                'Content-Type':'application/json'
            },
            'body': JSON.stringify(loan)
        });
        let body = await res.text();
        setFeedback(body);
        if(body === 'Loan requested successfully'){
            props.fn();
        }
    }
    async function getLoans(){
        let res = await fetch(`http://client.api.safeportal.win/api/loans/fetch?c=${props.data.cust_no}`,{
            'method':'GET',
            'headers':{
                'Authorization': 'Bearer '+props.session.token,
                'Content-Type':'application/json'
            } 
        });
        let body = await res.json();
        setData(body);
    }
    useEffect(()=>{
        getLoans();
    }, [])
    return(
        <div className="row">
                <div className="col card m-3 p-3" id="reqloan">
                <h4 className="card-title">Request a loan</h4>
                <div className="card-body">
                <form>
                    <input type="number" className="form-control mb-2" placeholder="Amount" ref={amountRef} required/>
                    <div class="input-group mb-3">
                        <select name="tenure" id="tenure" className="form-control" ref={tenureRef}>
                            <option value="3">3 Months</option>
                            <option value="6">6 Months</option>
                            <option value="12">12 Months</option>
                        </select>
                        <span class="input-group-text">@</span>
                        <span class="input-group-text">17% P.A.</span>
                    </div>
                    <div className="text-center"><button type="button" className="btn btn-warning mb-2" onClick={loanService}>Request</button></div>
                </form>
                <p className={feedback==='Loan requested successfully'?"text-center text-success fw-bold":"text-center text-danger fw-bold"}>{feedback}</p>
                </div>
                </div>
                <div className="col card m-3 p-3 text-center" id="loanstatus">
                    <h4 className="card-title border-bottom">Loan status</h4>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Loan no</th>
                                <th>Amount</th>
                                <th>Tenure</th>
                                <th>Interest</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                                {data.map(i =>{
                                   return( <tr>
                                        <td>{i.loan_no}</td>
                                        <td>₹{i.loan_amount}</td>
                                        <td>{i.loan_tenure} Months</td>
                                        <td>{i.interest}%</td>
                                        <td><span className={i.loan_status==='Processing'?"badge rounded-pill bg-info":i.loan_status==='Approved'?"badge rounded-pill bg-success":"badge rounded-pill bg-danger"}>{i.loan_status}</span></td>
                                    </tr>
                                   )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>
    )

}
export default Loans;