import { useRef, useState } from "react";

function CreateAccount(props){
    const [ac, setAc] = useState();
    const [feedback, setFeedback] = useState('');
    const nameRef = useRef();
    const numRef = useRef();
    const emailRef = useRef();
    const addressRef = useRef();
    const obalRef = useRef();
    const aadharRef = useRef();
    const panRef = useRef();
    function generateAc(){
        setAc(Math.floor(Math.random()*(9999999999999999-1000000000000000)+1000000000000000));
    }
    async function makeAc(){
        const acNo = ac;
        const obal = obalRef.current.value;
        const aadhar = aadharRef.current.value;
        const pan = panRef.current.value;
        const name = nameRef.current.value;
        const num = numRef.current.value;
        const address = addressRef.current.value;
        const email = emailRef.current.value;
        const accountDetails = {
            'account_number': acNo,
            'balance': obal
        }
        if(!name){
            return setFeedback("Name cannot be empty");
        }
        if(!num){
            return setFeedback("Mobile number cannot be empty");
        }
        if(!address){
            return setFeedback("Address field cannot be empty");
        }
        if(!email){
            return setFeedback("Email cannot be empty");
        }
        if(!obal || obal <=0){
            return setFeedback("Invalid Opening Balance!");
        }
        if(!ac){
            return setFeedback("Generate account number first");
        }
        if(!aadhar){
            return setFeedback("Aadhar cannot be empty!");
        }
        if(!pan){
            return setFeedback("Pan cannot be empty!");
        }

        let res = await fetch(`http://staff.api.safeportal.win/api/staff/create/bankacc`,{
            method: 'POST',
            headers: {
                'Authorization':'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(accountDetails)
        });
        let body = await res.json();
        //console.log(body);
        if(body.cust_no){
            setFeedback("1/3 Success");
            updateProofs(body.cust_no);
        }else{
            setFeedback('Error creating account!');
        }
    }
    async function updateProofs(c){
        const aadhar = aadharRef.current.value;
        const pan = panRef.current.value;
        const proofs = {
            "aadhar":aadhar,
            "pan":pan
        }
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/attach/proofs?c=${c}`,{
            method: 'POST',
            headers: {
                'Authorization':'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(proofs)
        });
        let body = await res.text();
        //console.log(body);
        if(body === 'PROOFS ADDED SUCCESSFULLY'){
            setFeedback("2/3 Success");
            updateDetails(c);
        }else{
            setFeedback('Error adding proofs!');
        }
    }
    async function updateDetails(c){
        const name = nameRef.current.value;
        const num = numRef.current.value;
        const email = emailRef.current.value;
        const address = addressRef.current.value;
        const details ={
            "cust_name":name,
            "email":email,
            "mobile_number":num,
            "address":address
        }
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/add/details?c=${c}`,{
            method: 'POST',
            headers: {
                'Authorization':'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(details)
        });
        let body = await res.text();
        //console.log(body);
        if(body === 'Personal details updated successfully!'){
            setFeedback("3/3 Success");
        }else{
            setFeedback('Error updating details!');
        }
    }
    return(
        <div className="container col-8 text-center">
            <form>
            <div className="container m-2 pb-2 border-bottom">
                <h4 className="text-start">Personal details</h4>
                <div className="input-group mb-2">
                    <input type="text" className="form-control" placeholder="Name" ref={nameRef}/>
                    <input type="number" className="form-control" placeholder="Mobile Number" ref={numRef}/>
                </div>
                <div className="input-group mb-2"> 
                <input type="text" className="form-control" placeholder="Email" ref={emailRef}/>
                </div>
                <div className="mb-2 border-bottom">
                <textarea maxLength="200" className="form-control" placeholder="address" ref={addressRef}/>
                </div>
            </div>
            <div className="container m-2 pb-2 border-bottom">
                <h4 className="text-start">Account</h4>
                <div className="input-group mb-2">
                    <input className="form-control" value={ac} placeholder="Click to Generate Account Number" disabled readonly/>
                    <button type="button" className="btn btn-warning" onClick={generateAc}>⟳</button>
                </div>
                <div className="col-3">
                    <input type="number" className="form-control" placeholder="Opening balance" ref={obalRef}/>
                </div>
            </div>
            <div className="container m-2 p-2 border-bottom">
                <h4 className="text-start">Proofs</h4>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Aadhar no" ref={aadharRef}/>
                    <input type="text" className="form-control" placeholder="PAN" ref={panRef}/>
                </div>
            </div>
            <div className="text-center mb-3">
                <button type="button" className="btn btn-warning" onClick={makeAc}>Open Account</button>
            </div>
            </form>
            <p className={feedback.includes('Success')?"text-success fw-bold":"text-danger fw-bold"}>{feedback}</p>
        </div>
    )
}
export default CreateAccount;