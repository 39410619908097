import { useState } from "react";
import FrontPage from "./Bundles/FrontPage";
import TokenContext from "./Contexts/Token";
import {Routes, Route} from "react-router-dom";
import Home from "./Bundles/Home";
import NavBar from "./Components/NavBar";
import Error from "./Bundles/Error";
import LoginA from "./Components/LoginA";
import Registration from "./Components/Registration";
import SessionNav from "./Components/SessionNav";
import StaffLogin from "./pages/Staff/StaffLogin";
import Staff from "./pages/Staff/Staff";
import Investors from "./Components/Investors"

function App() {
  const [session, setSession] = useState({token:'', username:''});
  return (
    <TokenContext.Provider value={[session, setSession]}>
    <div>
    {session.token? <SessionNav/>:<NavBar/>}
    <Routes>
      <Route path="/" element={<FrontPage/>}/>
      <Route path="/home" element={<Home/>}/>
      <Route path="/error" element={<Error/>}/>
      <Route path="/login" element={<LoginA/>}/>
      <Route path="/register" element={<Registration/>}/> 

      <Route path="/staff/login" element={<StaffLogin/>}/> 
      <Route path="/staff/dashboard" element={<Staff/>}/>
      <Route path="/investors" element={<Investors/>}/>

    </Routes>
    </div>
    </TokenContext.Provider>
  
  );
}

export default App;
