import LnR from "../Components/LnR";
import Slider from "../Components/Slider";

function FrontPage(){
    return(
        <div className="container-fluid row">
            <div className="col-9 mt-3">
                <Slider/>
            </div>
            <div className="col p-3 mt-3">
                <LnR/>
            </div>
        </div>
    )
}
export default FrontPage;