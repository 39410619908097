import React, { useContext } from 'react'
import { useRef, useState } from "react";
import TokenContext from '../../Contexts/Token';
import {Navigate} from "react-router-dom";
function StaffLogin(){
    const emailRef = useRef();
    const passRef = useRef();
    const [feedback, setFeedback] = useState(" ");
    const [session, setSession] = useContext(TokenContext);
    async function Login(){
        const email = emailRef.current.value; 
        const password = passRef.current.value;
        const data = {
            'email':email,
            'password':password
        };
        if(!email || !password){
            return setFeedback("FIELDS CANT BE EMPTY")
        }
        let response = await fetch(`http://staff.api.safeportal.win/authenticate`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await response.json();
        //console.log(body);
        setSession({'token':body.token, 'username':email});
        if(!session.token){
            setFeedback("INVALID USERNAME OR PASSWORD!");
        }else{
        }
    }
    return(
    <div className="container col-5 mt-5 text-center border p-3">
        <form>
        <h1>Staff Login</h1>
            <div className="form-floating col-auto mt-3 mb-3 text-center">
                <input type="text" id="username" className="form-control col-2" placeholder="username" ref={emailRef} required/>
                <label htmlFor="username">Enter email:</label>
            </div>
            <div className="form-floating">
            <input type="password" id="password" className="form-control col-2" placeholder="password" ref={passRef} required/>
                <label htmlFor="password">Enter password:</label>
            </div>
            <div className="mt-3 mb-0 pb-0">
                <button type="button" onClick={Login} className="btn btn-danger">Login</button>
            </div><br/>
            <p className="text-danger mb-0 pb-0">{session.token? <Navigate to="/staff/dashboard" replace={true}/>:<strong>{feedback}</strong>}</p>
        </form>
    </div>
    );
}
export default StaffLogin;