import { useRef, useState } from "react"

function Deposit(props){
    const cnoRef = useRef();
    const amountRef = useRef();
    const [feedback, setFeedback] = useState('');
    async function doDeposit(){
        const cno = cnoRef.current.value;
        const amount = amountRef.current.value;
        if(!cno || !amount){
            return setFeedback('Customer number or amount cannot be empty!');
        }
        if(amount<=0){
            return setFeedback('Amount cannot be zero or negative');
        }
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/deposit?c=${cno}&amount=${amount}`,{
            method: 'PUT',
            headers: {
                'Authorization':'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            },
        });
        let body = await res.text();
        setFeedback(body);
    }
    return(
        <div className="container col-8 text-center">
            <form>
                <div className="input-group mb-3 mt-3">
                <input type="number" className="form-control m-2" ref={cnoRef} placeholder="Customer number"/>
                <input type="number" className="form-control m-2" ref={amountRef} placeholder="Amount"/>
                </div>
                <div className="text-center mb-3">
                    <button type="button" className="btn btn-warning" onClick={doDeposit}>Deposit</button> 
                </div>
            </form>
            <p className={feedback==='Deposited Money Successfully'?"text-success fw-bold":"text-danger fw-bold"}>{feedback}</p>
        </div>
    )
}
export default Deposit;