import React from 'react'
import { useRef, useState } from "react";

function Registration(){
    const [userExists, setUserExists] = useState(true);
    const [check, setCheck] = useState(false);
    const userRef = useRef();
    const pwdRef = useRef();
    const cpwdRef = useRef();
    const acRef = useRef();
    const otpRef = useRef();
    const [error, setError] = useState();
    const [acValid, setAcValid] = useState();
    const [success, setSuccess] = useState();
    const [authorized, setAuthorized] = useState(null);
    const [otpID, setOtpID] = useState(null);
    function checkUser(){
        const username = userRef.current.value;
        let str = username.replaceAll(" ","")
        if(str.length===0) return ;
        setCheck(true);
        fetch(`http://client.api.safeportal.win/api/user/check?username=${username}`)
            .then(res => {
                setUserExists(!res.ok);
                //console.log(userExists);
            }).catch(error=>{console.log(error)});
    }
    function checkAccount(){
        const ac = acRef.current.value.trim();
        fetch(`http://client.api.safeportal.win/api/account/accountChecker?accountnumber=${ac}`)
        .then(async res => {
            if (res.ok) {
                await mailHandler(ac);
            }
            setAcValid(res.ok);
        }).catch(error=>{console.log(error)});
    }
    async function mailHandler(ac){
        let res = await fetch(`http://client.api.safeportal.win/api/pd/ac/email?ac=${ac}`);
        let body = await res.text();
        if(body.includes('@')){
            await EmailHandler(body)
        }else{
            setError("Something went wrong");
        }
    }
    function register(){
        const user = userRef.current.value;
        const pwd = pwdRef.current.value;
        const cpwd = cpwdRef.current.value;
        const data = {
            'username':user,
            'password':pwd
        };
        if(pwd!==cpwd){
            return setError("PASSWORDS DO NOT MATCH!");
        }
        if((pwd.length || cpwd.length )<8){
            return setError("PASSWORD SHOULD HAVE 8 OR MORE CHARACTERS!");
        }
        if(userExists){
            return setError("PLEASE ENTER A VALID USERNAME");
        }
        if(!acValid){
            return setError("INVALID ACCOUNT NUMBER!");
        }
        if(!authorized){
            return setError("Enter the OTP sent to your registered email and verify!");
        }
        fetch("http://client.api.safeportal.win/register",{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        }).then(res =>{
            linkBank();
            //console.log(res);

        }).catch(error =>{
            setError("Something went wrong");
           // console.log(error);
        });
    };
    async function linkBank(){
        const user = userRef.current.value;
        const ac = acRef.current.value;
        await fetch(`http://client.api.safeportal.win/api/account/assign?username=${user}&accountnumber=${ac}`,{
            method:'POST'
        });
        //let body = await res.text();
       // console.log(body);
        setError("");
        setSuccess("Account Created Successfully");
    }
    async function validateOtp(){
        const data={
            'otp':otpRef.current.value,
            'state_id':otpID
        };
        let res = await fetch(`https://api.mojoauth.com/users/emailotp/verify`,{
            method: 'POST',
            headers:{
                'X-API-Key': 'test-6c76c797-d097-4fca-a6d2-e64256a8bc7a',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await res.json();
        setAuthorized(body.authenticated);
        if(!body.authenticated){
            setAuthorized(false);
        }
    }
    async function EmailHandler(e){
        const data ={
            'email':e
        };
        let res = await fetch(`https://api.mojoauth.com/users/emailotp`,{
            method: 'POST',
            headers:{
                'X-API-Key': 'test-6c76c797-d097-4fca-a6d2-e64256a8bc7a',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await res.json();
        await setOtpID(body.state_id);
        if(!body.state_id){
            return setError("Error");
        }

    }
    async function ResendOtp(){
        const data ={
            'state_id': otpID
        }
        let res = await fetch(`https://api.mojoauth.com/users/emailotp/resend`,{
            method: 'POST',
            headers:{
                'X-API-Key': 'test-6c76c797-d097-4fca-a6d2-e64256a8bc7a',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await res.json();
        if(!body.state_id){
            return setError('Failed to resend OTP! Please try again later!');
        }else{
            setOtpID(body.state_id);
            return setSuccess('Resent OTP Successfully!');
        }
    }
    return(
        <div>
        <form>
            <div className="form-floating input-group mt-3 mb-1 text-center">
            <input type="text" id="username" className="form-control" placeholder="Username:" ref={userRef} required/>
            <label htmlFor="username">Enter username:</label>
            <div className="input-group-append">
                <button className="btn btn-outline-success mt-2 m-1" type="button" onClick={checkUser}>Check</button>
            </div>
            </div>
            <p className={userExists? "text-danger":"text-success"}>{check? userExists? "❌User already exists":"✅Username available":""}</p>
            <div className="form-floating input-group mb-3 text-center">
            <input type="tel" pattern="[0-9]{16}" id="account_number" className="form-control" placeholder="Account number:" ref={acRef} required/>
            <label htmlFor="account_number">A/C Number:</label>
            <div className="input-group-append">
                <button className="btn btn-outline-success mt-2 m-1" type="button" onClick={checkAccount}>Check</button>
            </div>
            </div>
            <p>{acValid? "✅Validated":acValid===false?"❌Invalid":""}</p>
            {acValid? <div>
                <p className={"fw-bold text-danger"}>Enter OTP sent to registered email</p>
                <div  className="form-floating mb-3 text-center">
                <input type="text" id="Otp" className="form-control" placeholder="Otp:" ref={otpRef} required/>
                <label htmlFor="otp">Otp:</label>
                <div className="input-group-append mt-2 m-1">
                    <button className="btn btn-sm btn-success m-1" type="button" onClick={validateOtp} disabled={authorized}>Verify</button>
                    <button className="btn btn-sm btn-warning m-1" type="button" onClick={ResendOtp} disabled={authorized}>Resend OTP</button>
                </div>
                </div>
                <p>{authorized? "✅Verified":authorized===false?"❌Invalid OTP Entered":""}</p>
            </div>:''}
            <div className="form-floating mb-3 text-center">
            <input type="password" id="password" className="form-control" placeholder="Password:" ref={pwdRef} required/>
            <label htmlFor="password">Enter password:</label>
            </div>
            <div className="form-floating mb-3 text-center">
            <input type="password" id="cpassword" className="form-control" placeholder="Confirm Password:" ref={cpwdRef} required/>
            <label htmlFor="cpassword">Confirm password:</label>
            </div>
            <p className={error? "text-danger":"text-success"}>{error? error:success}</p>
            <div className="mt-3">
                <button type="button"  className="btn btn-danger" onClick={register}>Register</button>
            </div>
        </form>
    </div>
    );
}
export default Registration;
