import axios from "axios";
import { useRef, useState } from "react";

//https://upibankvalidator.com/api/upiValidation?upi=
function TransferMoney(props){
    const upiRef = useRef();
    const userRef = useRef();
    const amountRef = useRef();
    const moneyRef = useRef();
    const amountNRef = useRef();
    const acRef = useRef();
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [userV, setUserV] = useState();
    const token = props.session.token;
    function upiValidator(){
        let upi = upiRef.current.value;
        if(upi.length < 5){
            return setError("PLEASE ENTER A VALID UPI!")
        }
        axios.get(`https://paydigi.airtel.in/web/pg-service/v1/validate/vpa/${upi}`,{
            method:'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }).then(res =>{
            //console.log(res)
            setData(res);
        }).catch(error =>{
            console.log(error);
        })
    }
    async function Transfer(){
        setError('');
        let transaction_amount = amountRef.current.value;
        if(!data){
            return setError("PLEASE VERIFY THE UPI FIRST!")
        }
        if(!data.data.data.vpaValid) return setError("PLEASE ENTER A VALID UPI ID!")
        const beneficiary = data.data.data.payeeAccountName+' -UPI';
        if(transaction_amount > props.data.balance){ 
            return setError("YOU'RE TOO POOR TO PERFORM THIS OPERATION! (TRANSFER AMOUNT EXCEEDS YOUR BANK BALANCE)");
        }        
        if(transaction_amount<=0){ 
            return setError("TRANSFER AMOUNT CANNOT BE ZERO OR NEGATIVE");
        }
        const tx = {
            'transaction_amount':transaction_amount,
            'beneficiary':beneficiary
        };
        let res = await fetch(`http://client.api.safeportal.win/api/transactions/new/other?c=${props.data.cust_no}`,{
            'method':'POST',
            'headers':{
                'Authorization': 'Bearer '+token,
                'Content-Type':'application/json'
            },
            'body': JSON.stringify(tx)
        });
        let body = await res.text();
        setError(body);
       // console.log(body);
        if(body==='Transaction Successful!'){
            props.fn();
        }
    }
    function checkUser(){
        setError('');
        const u = userRef.current.value;
        fetch(`http://client.api.safeportal.win/api/user/check?username=${u}`)
            .then(res => {
                setUserV(!res.ok);
                //console.log(userExists);
            }).catch(console.error());
    }
    async function transferSib(){
        setError('');
        let u = userRef.current.value;
        let m = moneyRef.current.value;
        //console.log(m)
        if(u === props.session.username){
            return setError("YOU CANNOT SEND MONEY TO YOURSELF!");
        }
        if(m ==null){
            return setError("TRANSFER AMOUNT CANNOT BE EMPTY!");
        }
        if(m > props.data.balance){
            return setError("BALANCE INSUFFICIENT FOR THIS OPERATION!");
        }
        if(m<=0){
            return setError("TRANSFER AMOUNT CANNOT BE ZERO OR NEGATIVE");
        }
        const tx = {
            'transaction_amount':m,
            'beneficiary':u
        };
        let res = await fetch(`http://client.api.safeportal.win/api/transactions/new/local?c=${props.data.cust_no}`,{
            'method':'POST',
            'headers':{
                'Authorization': 'Bearer '+token,
                'Content-Type':'application/json'
            },
            'body': JSON.stringify(tx)
        });
        let body = await res.text();
        setError(body);
        //console.log(body);
        if(body==='Transaction Successful!'){
            props.fn();
        }
  
    }
    async function transferNeft(){
        const ac = acRef.current.value;
        const amount = amountNRef.current.value;
        if(!ac){
            return setError('ACCOUNT NUMBER CANNOT BE EMPTY!');
        }
        if(!amount){
            return setError("AMOUNT CANNOT BE EMPTY");
        }
        if(ac.toString().length < 16){
            return setError("PLEASE ENTER A VALID ACCOUNT NUMBER");
        }
        if(amount > props.data.balance){ 
            return setError("YOU'RE TOO POOR TO PERFORM THIS OPERATION! (TRANSFER AMOUNT EXCEEDS YOUR BANK BALANCE)");
        }        
        if(amount<=0){ 
            return setError("TRANSFER AMOUNT CANNOT BE ZERO OR NEGATIVE");
        }
        const tx = {
            'transaction_amount':amount,
            'beneficiary': ac+' -NEFT'
        };
        let res = await fetch(`http://client.api.safeportal.win/api/transactions/new/other?c=${props.data.cust_no}`,{
            'method':'POST',
            'headers':{
                'Authorization': 'Bearer '+token,
                'Content-Type':'application/json'
            },
            'body': JSON.stringify(tx)
        });
        let body = await res.text();
        setError(body);
        if(body==='Transaction Successful!'){
            props.fn();
        }
    }
    return(
        <div className="row">
            <div class="col-5 btn-group-vertical">
                <button type="button" className="btn btn-danger" data-bs-toggle="collapse" data-bs-target="#upi" aria-expanded="false" aria-controls="upi" onClick={()=>{setError('');} }>Transfer with UPI <span className="badge rounded-pill bg-success">instant</span></button>
                <button type="button" className="btn btn-danger mt-1" data-bs-toggle="collapse" data-bs-target="#siblocal" aria-expanded="false" aria-controls="siblocal" onClick={()=>{setError('');}}>Transfer to another Sib's bank user <span className="badge rounded-pill bg-success">instant</span></button>
                <button type="button" className="btn btn-danger mt-1" data-bs-toggle="collapse" data-bs-target="#neft" aria-expanded="false" aria-controls="neft" onClick={()=>{setError('');}}>Transfer with NEFT</button>
            </div>
            <div id="transfer" className="col-6">
            <div className="collapse indent card m-3 p-3" id="upi" data-bs-parent="#transfer" >
                <form>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Recipient's UPI ID" aria-label="Recipient's UPI ID" aria-describedby="verifyupi" ref={upiRef}/>
                        <button class="btn btn-warning" type="button" id="verifyupi" onClick={upiValidator}>Verify</button>
                    </div>
                    <div className="text-danger">{data?data.data.data.vpaValid?<p className="text-success">{data.data.data.payeeAccountName} ✓ Verified</p>:'❌ Invalid UPI ID!':""}</div> 
                    <div class="input-group mb-3">
                        <input type="number" class="form-control" placeholder="Amount to send" aria-label="Amount to send" aria-describedby="amount" ref={amountRef}/>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-warning" onClick={Transfer}>SEND</button>
                        <p className={error==='Transaction Successful!'?"text-success":"text-danger"}><strong>{error}</strong></p>
                    </div>
                    
                </form>
            </div>
            <div className="collapse indent card m-3 p-3" id="siblocal" data-bs-parent="#transfer" >
                <form>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="verifyuser" ref={userRef}/>
                        <button class="btn btn-warning" type="button" id="verifyuser" onClick={checkUser}>Verify</button>
                    </div>
                    <div className="text-danger">{userV?<p className="text-success"> ✓ Verified</p>:'❌ Invalid user!'}</div> 
                    <div class="input-group mb-3">
                        <input type="number" class="form-control" placeholder="Amount to send" aria-label="Amount to send/transfer" aria-describedby="money" ref={moneyRef}/>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-warning" onClick={transferSib}>SEND</button>
                        <p className={error==='Transaction Successful!'?"text-success":"text-danger"}><strong>{error}</strong></p>
                    </div>
                    
                </form>
            </div>
            <div className="collapse indent card m-3 p-3" id="neft" data-bs-parent="#transfer" >
            <form>
                    <div class="input-group mb-3">
                        <input type="tel" pattern="[0-9]{16}" class="form-control" placeholder="Recipient's account number" aria-label="Recipient's account number" ref={acRef}/>
                    </div>
                    <div class="input-group mb-3">
                        <input type="number" class="form-control" placeholder="Amount to send" aria-label="Amount to send/transfer" aria-describedby="money" ref={amountNRef}/>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-warning" onClick={transferNeft}>SEND</button>
                        <p className={error==='Transaction Successful!'?"text-success":"text-danger"}><strong>{error}</strong></p>
                    </div>
                    
                </form>
            </div>
        </div>
        </div>
    )
}
export default TransferMoney;