import React from 'react'
function Slider(){
    return(
        <div id="slider" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100" src='slide1.png' alt='slide1'/>
                </div>
                <div class="carousel-item">
                    <img className="d-block w-100" src='slide2.png' alt='slide2'/>
                </div>
                <div class="carousel-item">
                    <img className="d-block w-100" src='slide3.png' alt='slide3'/>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#slider" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#slider" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>  
    );
}
export default Slider;