function LoanCenter(props){
    const pendingLoans = props.loans.filter(i => i.loan_status === 'Processing');
    const closedLoans = props.loans.filter(i => i.loan_status !== 'Processing');
    async function approveLoan(i){
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/loan/updatestatus?l=${i.loan_no}&status=Approved`,{
            method:'PUT',
            headers: {
                'Authorization':'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            }
        });
        let body = await res.text();
        console.log(body)
        if(body === 'Loan status updated successfully!'){
            props.fn();
        }
    }
    async function RejectLoan(i){
        let res = await fetch(`http://staff.api.safeportal.win/api/staff/loan/updatestatus?l=${i.loan_no}&status=Rejected`,{
            method:'PUT',
            headers: {
                'Authorization':'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            }
        });
        let body = await res.text();
        console.log(body)
        if(body === 'Loan status updated successfully!'){
            props.fn();
        }
    }
    return(
        <div className="row text-center m-3">
        <div className="col-7">
        <h4>OPEN LOANS</h4>
            <table className="table">
                <thead>
                    <th>Loan no</th>
                    <th>User</th>
                    <th>Amount Requested</th>
                    <th>Status</th>
                    <th>Action</th>            
                </thead>
                <tbody>
                    {pendingLoans.map(i =>{
                        return(
                                <tr>
                                    <td>{i.loan_no}</td>
                                    <td>{i.accountDetails.user.username}</td>
                                    <td>₹{i.loan_amount}</td>
                                    <td>{i.loan_status}</td>
                                    <td><span className="badge rounded-pill bg-success" onClick={()=>{approveLoan(i)}}>Approve</span>/<span className="badge rounded-pill bg-danger" onClick={()=>{RejectLoan(i)}}>Reject</span></td>
                                </tr>
                            );
                    })}
                </tbody>
            </table>
        </div>
        <div className="col">
        <h4>CLOSED LOANS</h4>
            <table className="table">
                <thead>
                    <th>Loan no</th>
                    <th>User</th>
                    <th>Amount Requested</th>
                    <th>Status</th>            
                </thead>
                <tbody>
                    {closedLoans.map(i =>{
                        return(
                                <tr>
                                    <td>{i.loan_no}</td>
                                    <td>{i.accountDetails.user.username}</td>
                                    <td>₹{i.loan_amount}</td>
                                    <td><span className={i.loan_status==='Approved'?"badge rounded-pill bg-success":"badge rounded-pill bg-danger"}>{i.loan_status}</span></td>
                                </tr>
                            );
                    })}
                </tbody>
            </table>
        </div>
        </div>
    )
}
export default LoanCenter;