import { useEffect, useState } from "react";

function AccountDetails(props){
    const [data, setdata] = useState();
    async function getDetails(){
        let res = await fetch(`http://client.api.safeportal.win/api/pd/fetch?c=${props.ac.cust_no}`,{
            method:'GET',
            headers:{
                'Authorization': 'Bearer '+props.session.token,
                'Content-Type': 'application/json'
            }
        });
        let body = await res.json();
        setdata(body);
        //console.log(body)
    }
    useEffect(()=>{
        getDetails();
    }, []);
    return(
        <div class="row">
            <div className="col">
            <ul class="list-group list-group">
                <li class="list-group-item">Customer number: {props.ac.cust_no}</li>
                <li class="list-group-item">Account Number: {props.ac.account_number}</li>
            </ul>
            </div>
            <div className="col">
            <ul class="list-group list-group">
                {data?<div>
                    <li class="list-group-item">Name: {data.cust_name}</li>
                    <li class="list-group-item">Email: {data.email}</li>
                    <li class="list-group-item"> Mobile: {data.mobile_number}</li>
                    <li class="list-group-item">Address: {data.address}</li>
                </div>:""}
            </ul>
            </div>
        </div>
    )
}
export default AccountDetails;