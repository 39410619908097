import axios from "axios";
import { Component} from "react";
import TokenContext from "../Contexts/Token";

class Transactions extends Component{
    state={
        tx:[],

    }
    static contextType = TokenContext;
    async componentDidMount(){
        let txs= await axios.get(`http://client.api.safeportal.win/api/transactions/fetch?c=${this.props.data.cust_no}`,{
            method:'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+this.context[0].token
            }
        });
        let txsbody = await txs;
        this.setState({tx:txsbody.data.reverse()})
        //console.log(txsbody);
        this.props.tx(txsbody);
    }

    render(){
        let tx = this.state.tx;
        return(
            <div><table className="table">
            <thead>
            <tr>
                <th>Transaction ID</th>
                <th>Type</th>
                <th>Beneficiary</th>
                <th>Amount(₹)</th>
                <th>Date</th>
                </tr>
            </thead>
            <tbody>
            {tx.map(i=>{
                return(
                <tr>
                    <td>{i.transaction_id}</td>
                    <td>{i.beneficiary===this.context[0].username? <span className="badge rounded-pill bg-success">Receive</span>:<span className="badge rounded-pill bg-danger">Send</span>}</td>
                    <td>{i.beneficiary===this.context[0].username? 'SELF (FROM: '+i.accountDetails.user.username.toUpperCase()+')':i.beneficiary.toUpperCase()}</td>
                    <td className={i.beneficiary===this.context[0].username?"text-success fw-bold ":"text-danger fw-bold"}>{i.beneficiary===this.context[0].username? '+':'-'}{i.transaction_amount}</td>
                    <td>{i.date}</td>
                </tr>
                );
            })}</tbody></table></div>
        )
    }
}
export default Transactions;