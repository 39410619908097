import {Badge, Button, Modal} from "react-bootstrap";
import {useContext, useEffect, useRef, useState} from "react";
import {Navigate} from "react-router-dom";
import TokenContext from "../Contexts/Token";

function Verification(props){
    const [feedback, setFeedback]=useState('');
    const [email, setEmail] = useState('');
    const [otpID, setOtpID] = useState(null);
    const [authorized, setAuthorized] = useState(false);
    const [session, setSession] = useContext(TokenContext);
    const otpRef = useRef();
    async function Verify(){
        let res = await fetch(`http://client.api.safeportal.win/api/pd/email?username=${props.session.username}`,{
            headers:{
                'Authorization':'Bearer '+props.session.token,
                'Content-Type':'application/json'
            }
        });
        let body = await res.text();
        setEmail(body);
        if(!body.includes('@')) {
            return setFeedback('Error!');
        }
        EmailHandler(body);
    }
    async function EmailHandler(e){
        const data ={
            'email':e
        };
        let res = await fetch(`https://api.mojoauth.com/users/emailotp`,{
            method: 'POST',
            headers:{
                'X-API-Key': 'test-6c76c797-d097-4fca-a6d2-e64256a8bc7a',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await res.json();
        await setOtpID(body.state_id);
        if(!body.state_id){
            return setFeedback("Error");
        }

    }
    async function validateOtp(){
        const data={
            'otp':otpRef.current.value,
            'state_id':otpID
        };
        let res = await fetch(`https://api.mojoauth.com/users/emailotp/verify`,{
            method: 'POST',
            headers:{
                'X-API-Key': 'test-6c76c797-d097-4fca-a6d2-e64256a8bc7a',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await res.json();
        setAuthorized(body.authenticated);
        if(!body.authenticated){
            return setFeedback("Invalid OTP Entered!")
        }else{
            setSession(props.session);
            return setFeedback("Authorized Successfully!")
        }
    }
    async function ResendOtp(){
        const data ={
            'state_id': otpID
        }
        let res = await fetch(`https://api.mojoauth.com/users/emailotp/resend`,{
            method: 'POST',
            headers:{
                'X-API-Key': 'test-6c76c797-d097-4fca-a6d2-e64256a8bc7a',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await res.json();
        if(!body.state_id){
            return setFeedback('Failed to resend OTP! Please try again later!');
        }else{
            setOtpID(body.state_id);
            return setFeedback('Resent OTP Successfully!');
        }
    }
    useEffect(()=>{
        Verify();
    },[])
    return(
        <div className={"text-center"}>
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"text-center"}><p className={"text-start"}>An Otp has been sent to <strong>{email.substring(0, email.length/8)+'************'+email.substring(email.length-5,email.length)}</strong></p>
                    <div className={"text-end"}>
                        <input type={"text"} className={"form-control"} maxLength="6" placeholder={"Enter OTP"} ref={otpRef}/>
                        <Badge className={"bg-warning text-black"} onClick={ResendOtp}>Resend Otp</Badge>
                    </div>
                   <Button variant={"danger mt-2"} onClick={validateOtp}>
                       Authorize
                   </Button>
                    <p className={!feedback.toLowerCase().includes("success")? "text-danger" : "text-success"}>{feedback}</p>
                </Modal.Body>
            </Modal>
            {authorized?session.token?<Navigate to={"/home"} replace={true}/>:'Please wait...':''}
        </div>
    );
}
export default Verification;
